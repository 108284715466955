import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Container, Box, Stack, Divider, Card} from '@mui/material';
import landing_image from '../assets/evol-landing-image.png';
import landing_image_mobile from '../assets/evol-landing-image-mobile.png';
import AboutPage from './about';
import Navbar from '../components/Navbar';
import evol_logo from '../assets/Logo_Primary.png';
import truth_seeker from "../assets/podcasts/truth-seekers.mp3"
import system_languages from "../assets/podcasts/systems-speak-different-languages.mp3"

const LandingPage = () => {
  const navigate = useNavigate();

  const goToAbout = () => {
    console.log('calling About');
    navigate('/about');
  }
  React.useEffect(() => {
    document.title = "Evol AI";
  }, []);

  return (
    <div>
      
      <Navbar />
      <Box
        sx={{
          height: '85vh',
          backgroundImage: {
            xs: `url(${landing_image_mobile})`,
            sm: `url(${landing_image})`
          },
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 20px',
        }}
      >
    {/* <Typography variant="h4" align="left" sx={{color: '#E2DEEA', ml: 5, mt: 30}} gutterBottom>
      In today's enterprises data flows through a complex web of systems - ERP, 
      CRM, financials and specialized applications. Changes in business process, configurations, change management and operator errors 
      introduce discrepancies across system data. These inconsistencies lead to conflicting reports, reconciliation failures that hinder decision-making and miss opportunities 
      that could otherwise propel your business forward.

    </Typography> */}
      </Box>
      <Container sx={{ padding: '50px 0', margin: '50px 0', maxWidth: '80%', margin: 'auto' }}>
        <Typography variant="h3" align="center" sx={{color: '#763AEA'}} gutterBottom>
          Products
        </Typography>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
          <Box flex={1}>
            <Card sx={{ borderRadius: '16px', border: '3px solid #B92DE0', margin: '10px' }}>
              <Typography variant="h5" align="center" sx={{ padding: '10px' }}>
                Business Process Reconciliation
              </Typography>
              <Divider variant="middle" sx={{ my: 1 }} />
              <Typography align="left" sx={{ padding: '10px' }}>
              In today's enterprises, data flows through a maze of systems—ERP, CRM, financial platforms, and specialized applications. Changes in business processes, configurations, and operator errors introduce discrepancies across system data. These inconsistencies lead to conflicting reports, reconciliation failures, and stalled decision-making—missed opportunities that could propel your business forward. Imagine AI agents working behind the scenes, reconciling data discrepancies and raising real-time alerts across all systems. They provide a transparent, coherent view of your enterprise data, empowering business and IT leaders with accurate insights to drive growth. With consistent data, your organization can make swift, confident decisions and capitalize on opportunities like never before.
              </Typography>
            </Card>
          </Box>
          <Box flex={1}>
            <Card sx={{ borderRadius: '16px', border: '2px solid #733AEB', margin: '10px' }}>
              <Typography variant="h5" align="center" sx={{ padding: '10px' }}>
              Code Translation & Optimization
              </Typography>
              <Typography variant="subtitle1" align="center" sx={{ color: 'grey' }}>coming soon</Typography>
              <Divider variant="middle" sx={{ my: 0}} />
              <Typography align="left" sx={{ padding: '10px' }}>
              Software maintenance and upgrades are often a bane for organizations. This is where generative AI shines. We leverage AI agents to understand your codebase, creating documentation that caters to business users, developers, and auditors. They build comprehensive test cases and regression suites with synthetic data to test various scenarios and code behavior. The agents identify code sections to fix and optimize, enhancing performance and security. They can also transpile code into modern languages with equivalent test scripts and automated testing for better maintainability and scalability. By demystifying your codebase, AI agents enable seamless integration and innovation, empowering your team to move forward with confidence.

              </Typography>
            </Card>
          </Box>
          <Box flex={1}>
            <Card sx={{ borderRadius: '16px', border: '2px solid #733AEB', margin: '10px' }}>
              <Typography variant="h5" align="center" sx={{ padding: '10px' }}>
              Continual Training & Assessments
              </Typography>
              <Typography variant="subtitle1" align="center" sx={{ color: 'grey' }}>coming soon</Typography>
              <Divider variant="middle" sx={{ my: 0 }} />
              <Typography align="left" sx={{ padding: '10px' }}>
              Training and upskilling are cornerstones of keeping your staff current and relevant. Using generative AI, we offer training and assessments customized to your organization's products and services. Our domain-specific AI agents generate assessments based on domain knowledge and your offerings. They create various types of content—multiple-choice questions, true or false, case studies, and long-form answers. By providing continual assessments with feedback, they ensure employees comprehend and can effectively apply the content. This method enhances retention, as testing oneself with feedback is one of the most effective ways to retain information. Training on new capabilities becomes quick, economical, and up-to-date.
              </Typography>
            </Card>
          </Box>
        </Stack>
      </Container>
      <Container sx={{ padding: '50px 0', margin: '50px 0', maxWidth: '80%', margin: 'auto' }}>
        <Typography variant="h3" align="center" sx={{color: '#763AEA'}} gutterBottom>
          Value Narratives
        </Typography>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
          <Box flex={1}>
            <Card sx={{ borderRadius: '16px', border: '2px solid #733AEB', margin: '10px' }}>
              <Typography variant="h5" align="center" sx={{ padding: '10px' }}>
                The Truth Seekers
                <Typography> AI agents on a mission to decode enterpise data semantics</Typography>
              </Typography>
              <audio controls>
                <source src={truth_seeker} type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
            </Card>
          </Box>
          <Box flex={1}>
            <Card sx={{ borderRadius: '16px', border: '2px solid #733AEB', margin: '10px' }}>
              <Typography variant="h5" align="center" sx={{ padding: '10px' }}>
                When Systems Speak Different Languages
                <Typography> Babel fish, AI agents as the translators of enterprise data</Typography>
              </Typography>
              <audio controls>
                <source src={system_languages} type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
            </Card>
          </Box>
        </Stack>
      </Container>



      {/* Benefits Section */}
      <Container sx={{ padding: '50px 0' }}>
        <Typography variant="h3" align="center" sx={{color: '#763AEA'}} gutterBottom>
          Benefits
        </Typography>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={8}>
          <Box flex={1}>
            <Typography variant="h5" align="center">
              Bespoke
            </Typography>
            <Typography align="left">
            Tailor the solution to fit your unique business needs through natural language instructions. Our AI agents learn your interfaces and their formats. Your processes don't have to change to use our solution.

            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="h5" align="center">
              Adapt fast
            </Typography>
            <Typography align="left">
            We anticipate change. Our AI agents quickly attune to your operations and evolving business environment. No costly and time-consuming re-engineering—our smart agents learn your business.
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="h5" align="center">
              Your data has answers
            </Typography>
            <Typography align="left">
            Our reconciliation solution can answer your questions based on your data without pre-configured reports. Gain insights and unlock answers by simply asking questions of your data.
            </Typography>
          </Box>
        </Stack>
      </Container>

      {/* Call to Action */}
      <Card sx={{ 
        margin: '80px 50px', 
        padding: '20px', 
        border: '2px solid #733AEB', 
        borderRadius: '16px' 
      }}>

        <Typography variant="h3" sx={{mb: 5}}>
            Experience AI reconciliation
        </Typography>
          
        <Typography variant="h6" align="left" sx={{ ml: 5, mr: 5, mb: 5 }}>
        Evol AI agents are dynamic entities that evolve alongside your business. By continuously learning from your data they provide a robust and adaptable approach to data reconciliation.
        Evol AI agents harness the power of cutting-edge language models, this ensures more accurate data interpretation, smarter reconciliations that scale with your business.

        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
          
          <Button 
            variant="contained" 
            color="primary" 
            size='large'
            onClick={() => navigate('/contact')}
          >
            Ask us for a demo
          </Button>
        </Box>
      </Card>
      <Card sx={{ 
        margin: '50px 40px', 
        padding: '60px', 
        backgroundColor: '#733AEB', 
        color: 'white',
        borderRadius: '16px' 
      }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
          <Typography variant="h3" sx={{mb: 5}} gutterBottom>
            Proven track record
            <Typography variant="h5" sx={{ fontStyle: 'italic', textAlign: 'left', mb: 2 }} >
            Few of our show case projects that have delivering high-quality, scalable and innovative solutions to our clients. 
          </Typography>
          </Typography>

          <Typography variant="h5" sx={{ fontStyle: 'italic', textAlign: 'left', mb: 2 }}>
          Ensuring Financial Data Integrity
          <Typography variant="h6"> 
          Maintain consistent financial figures across budgeting tools, expense tracking systems, and general ledger accounts. This consistency avoids costly errors during audits and enhances compliance with financial regulations.
          </Typography>
          </Typography>

          <Typography variant="h5" sx={{ fontStyle: 'italic', textAlign: 'left', mb: 2 }}>
          Optimizing Supply Chain Coordination
          <Typography variant="h6"> 
          Synchronize procurement and inventory data between your supply chain systems and accounting software. This alignment prevents overstock situations or stockouts, ensuring optimal inventory levels and reducing holding costs.
          </Typography>
          </Typography>

          <Typography variant="h5" sx={{ fontStyle: 'italic', textAlign: 'left', mb: 2 }}>
          Customer Experience Enhancement
          <Typography variant="h6"> 
          Keep customer data consistent between marketing platforms and customer service portals to deliver personalized and effective interactions.
          </Typography>
          </Typography>
          
          <Typography variant="h5" sx={{ fontStyle: 'italic', textAlign: 'left', mb: 2 }}>
          Regulatory Compliance and Reporting
          <Typography variant="h6"> 
          Automatically reconcile data across various systems to ensure compliance with industry regulations. Simplify reporting requirements and reduce the risk of penalties associated with non-compliance.
          </Typography>
          </Typography>
          
        </Box>
      </Card>

      {/* Footer */}
      <Box sx={{ backgroundColor: '#f5f5f5', padding: '20px 0' }}>
        <Container>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={4} justifyContent="space-between">
            <Link to="/about" style={{ textDecoration: 'none' }}>
              <Typography variant="subtitle1">About</Typography>
            </Link>
            <Link to="/resources" style={{ textDecoration: 'none' }}>
              <Typography variant="subtitle1">Resources</Typography>
            </Link>
            <Link to="/privacy" style={{ textDecoration: 'none' }}>
              <Typography variant="subtitle1">Privacy</Typography>
            </Link>
            <Link to="/contact" style={{ textDecoration: 'none' }}>
              <Typography variant="subtitle1">Contact Us</Typography>
            </Link>
          </Stack>
        </Container>
      </Box>
    <Box sx={{ backgroundColor: '#f5f5f5', padding: '10px 0', textAlign: 'center' }}>
      <Typography variant="body2" color="text.secondary">
        © Productonics 2024
      </Typography>
    </Box>
    </div>
  );
};

export default LandingPage;
